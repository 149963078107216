.control-style {
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 15px
    /* background: #ffffff7d; */
}

.control-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 731px;
    height: 100%;
    /* background: #D9D9D9; */
    background: #fafafa;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    /* transform: translateY(-15px); */
}

    .playlist-button {
        width: 54px;
        height: 54px;
        position: absolute;
        right: 15px;
        border-radius: 50%;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        background: #fafafa;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .control-panel {
        
        cursor: pointer;
        transition: all ease-in-out 200ms;
    }

    .control-panel:hover {
        color: rgb(240 46 170 / 40%);
    }

.control-style > .control-container .buttons {
    display: inline-block;
    padding: 20px;
    cursor: pointer;
}

#activeSounds {
    z-index: 100;
    background: white;
    padding: 20px;
    border-radius: 2px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    right: 55px;
    bottom: 45px;
    /* transform: translateX(-60px); */
    overflow: hidden;
}
