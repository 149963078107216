.card {
/*  display: none;*/
  font-size: 5px;
}

.card.expanded {
  background: #fff;
  width: 340px;
  height: 340px;
  will-change: transform;
  border-radius: 0.6rem;
  display: block !important;
  position: fixed;
  top: calc(50% - 200px);
  left: calc(50% - 200px);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  padding: 30px;
  font-size: 12px;
  z-index: 2000;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  background: rgb(86 35 121 / 11%);
  border-radius: 50%;
  color: #4d205f6e;
  cursor: pointer;
  transition: all 200ms ease-in-out;

}
  
  .close:hover {
    background: rgb(86 35 121 / 20%);
  }

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  transition: all 2000ms ease-in-out;
}

.block {
/*  background: red;*/
}

.tempStyle {
/*  display: none;*/
  background: #ffffffbd;
  border-radius: 5%;
}

