.nav-style {
    width: 100%;
    /* border-bottom: 2px dashed var(--main-color); */

    display: flex;
    justify-content: center;

    position: sticky;
    top: 0;
    /* background: #ffffff7d; */
    z-index: 1;
    color: #403737;
}

@media (min-width: 476px) {
    .nav-container {
        /* width: 540px; */
        margin: 5px 5px 5px 5px !important;
    }

    .logo {
        padding: 6px !important;
    }
}

@media (min-width: 768px) {
    .nav-container {
        /* width: 720px; */
        margin: 5px 5px 5px 5px !important;
    }
}

@media (min-width: 992px) {
    .nav-container {
        /* width: 960px; */
        margin: 5px 5px 5px 5px !important;
    }
}

@media (min-width: 1050px) {
    .nav-container {
        /* width: 1000px; */
        margin: 5px 5px 5px 5px !important;
    }
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;


    width: 1080px;
    height: 100%;
    background: #fafafa;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 12px;
}

.nav-style > .nav-container .links {
    display: inline-block;
    padding: 20px;
    cursor: pointer;
    text-decoration: none;
    color: #403737;
}

.links {
    padding: "20px 20px 15px 20px";
}

.logo {
    
}