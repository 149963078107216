.mainview {
    display: flex;
    justify-content: center;

    height: calc(100% - 123.2px);
}

.mainview > .container {
    width: 1080px;
    height: 100%;
        display: inline-table;
    /* border-left: 2px dashed var(--main-color);
    border-right: 2px dashed var(--main-color); */

}