/** {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}*/

.expandedSound {
	background: white;
	padding: 1rem 1rem;
	z-index: 1000;
    position: relative;
    font-size: initial;
    width: fit-content;
/*    will-change: auto;*/
}

	.expandedSound p {
		padding-top: 1rem;
		line-height: 150%;
	}

	.expanded {
		width: 500px;
	}