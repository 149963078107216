:root {
  --main-color: rgba(0, 0, 0, 0.693);
  --active-color: rgba(192, 29, 29, 0.69);
  --hover-color: rgb(240 46 170 / 80%);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal-open {
/*  overflow: hidden;*/
  transition: all 200ms ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
