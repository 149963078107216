.gradient {
/*    background: radial-gradient(131.26% 141.9% at 78.68% 27.52%, #5C228B 0%, rgba(112, 34, 139, 0.496875) 16.5%, rgba(131, 34, 139, 0) 100%);*/
    width: 100%;
    height: 100vh;
    position: fixed;
}

    .motionTrees {
        transition: all 1600ms ease-in-out;
        will-change: transform;
    }

    .motionMountains {
        transition: all 1800ms ease-in-out;
        will-change: transform;
    }

    .motionCamp {
        transition: all 0ms ease-in-out;
        will-change: transform;
    }

    .trees {
        background-image: url('../images/trees.svg');

        opacity: 1;
        position: absolute;
        bottom: 1px;
        width: 113vw;
        height: 39%;
        z-index: 1000;
        background-repeat: no-repeat;
        background-size: cover;
        left: -13%;
        filter: opacity(0.85);
    }
    .gradient-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

        .mTrees1 {
            left: -13.5%;
        }

        .mTrees2 {
            left: -14%;
        }

        .mTrees3 {
            left: -14.5%;
        }

        .mTrees4 {
            left: -15%;
        }

    .mountains {
        background-image: url('../images/mountains.svg');
         
        opacity: 1;
        position: absolute;
        bottom: 1px;
        width: 113vw;
        height: 39%;
        z-index: 800;
        background-repeat: no-repeat;
        background-size: cover;
        left: -8%;
        filter: opacity(0.7);
    }

        .mMountains1 {
            left: -8.2%;
        }

        .mMountains2 {
            left: -8.4%;
        }

        .mMountains3 {
            left: -8.6%;
        }

        .mMountains4 {
            left: -8.8%;
        }

    .camp {
        background-image: url('../images/camp.svg');

        opacity: 1;
        position: absolute;
        bottom: 65px;
        width: 23%;
        height: 9%;
        z-index: 1200;
        background-repeat: no-repeat;
        background-size: cover;
        left: 50%;
        transform: translateX(-50%);
        filter: opacity(0.85);
    }
    
    .star {
        position: absolute;
        width: 100%;
        height: 100%;
    }
      
    .pixel {
        opacity: 0;
        position: absolute;
        border-radius: 50%;
        background-color: white;
        animation: blink 5.5s linear infinite;
        animation-delay: 4s;
    }
      
    @keyframes blink {
        0% {
            opacity: 0;
            transform: scale(1);
        }
        50% {
            opacity: 1;
            transform: scale(1.1);
        }
        100% {
            opacity: 0;
            transform: scale(1);
        }
    }