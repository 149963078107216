.volume {
    background: rgba(217, 217, 217, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    width: 400px;
    height: 20px;
    /* background: red; */
    /* border-left: 2px dashed var(--main-color);
    border-right: 2px dashed var(--main-color);
    border-bottom: 2px dashed var(--main-color); */
    position: fixed;
    top: 141px;
    /* left: calc(35%); */
    transition: all 100ms ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
}

.soundGrid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    /* grid-gap: 18px 23px; */
    grid-auto-rows: 100px;
    align-items: center;
    justify-items: center;
    top: 40px;
    position: relative;
}

.Grid.New div:nth-child(5) {
  display: none;
}

.serachNotFound {
    display: none;
    width: 50px;
    height: 50px;
/*    background: red;*/
}

    .serachNotFound.cancel {
        display: block !important;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        width: auto;
        color: white;
        font-size: 22px;
        background: rgb(217 217 217 / 31%);
        padding: 15px;
        border-radius: 5px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

.fOut {
   animation: fIn 0.8s ease-in;
}
    @keyframes fOut {
      0% {
        opacity: 0;
/*        transform: translateY(0px);*/
      }
      50% {
        opacity: 0.5;
/*        transform: translateY(-2.5px);*/
      }
      100% {
        opacity: 1;
/*        transform: translateY(-5px);*/
      }
    }
    
.fIn {
   animation: fIn 0.1s ease-in-out;
}

    @keyframes fIn {
      0% {
        opacity: 0;
        transform: translate(-50%, -35%);
      }
      100% {
        opacity: 1;
        transform: translate(-50%, -50%);
      }
    }

    /*.container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }*/