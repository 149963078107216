.sound-style {
    background: rgba(217, 217, 217, 0.7);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 56px;
    height: 56px;
    /* border: 2px dashed var(--main-color); */
    cursor: pointer;
    order: 0;
    transition: all 300ms ease-in-out 0.2s !important;
    will-change: transform;
    backface-visibility: hidden;

    z-index: 0;

    
}

    .sound-style.firstLoad {
        opacity: 0 !important;
    }

    .pressed:hover {
        transform: scale(0.95) !important;
        transition-delay: 0.6s;
        box-shadow: none;
        background: rgb(217 217 217 / 96%);
    }

    /*.sound-style:hover {
        animation: pressed 6s ease-in-out  !important;
    }

    @keyframes pressed {
        0% {
            transform: scale(1) translateY(0px);
        }

        70% {
            transform: scale(0.95) translateY(0px);
        }

        100% {
            transform: scale(0.9) translateY(0px);
        }
    }*/

    .moreButton {
        opacity: 0;
        width: 17px;
        height: 17px;
        border-radius: 50%;
/*        background: #211c31bf;*/
        transition: all 150ms ease-in-out;
        position: absolute;
        /*top: -3px;
        right: -7px;*/
        color: #ffffff9e;
        cursor: pointer;

        transform: translate(27px, -21px);

        display: flex;
        justify-content: center;
        align-items: center;
    }

    .moreButton:hover {
        transform: scale(1) translate(27px, -21px);
        box-shadow: 0 0 0 15px rgba(155, 255, 255, 0.5);
        padding: 9px;

    }

    /*.moreButton.displayMoreButton::before {
        content: 'test';
        position: absolute;
  bottom: -10px;
  left: 0;
  height: 10px;
  width: 100%;
  background-color: red;
  border-radius: 50%;
  margin: 10px;

    }*/


    .moreButton.displayMoreButton {
        opacity: 1;

    }

.sound-style.animate {
    animation-name: fadeOutFadeIn;
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    opacity: 0 !important; 
    /* display: none !important; */
}

.activeSoundContent.animate {
    display: none !important;
}

    @keyframes fadeOutFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.sound-style.active {
    /* border: 2px dashed var(--active-color); */
    animation: float-heart 6s ease-in-out infinite;
    /* https://getcssscan.com/css-box-shadow-examples */
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
    box-shadow: rgba(240, 46, 170, 0.4) 0px 2px, rgba(240, 46, 170, 0.3) 0px 5px, rgba(240, 46, 170, 0.2) 0px 10px, rgba(240, 46, 170, 0.1) 0px 12px, rgba(240, 46, 170, 0.05) 0px 15px !important;
}

    @keyframes float-heart {
        0% {
            transform: scale(1) translateY(0px);
        }

        70% {
            transform: scale(1.05) translateY(0px);
        }

        100% {
            transform: scale(1) translateY(0px);
        }
    }

.sound-style.hide {
    display: none;
    /* opacity: 0 !important;
  pointer-events: none;
  position: absolute; */

  /* opacity: 0 !important; */
  /* visibility: hidden !important; */
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Add transitions for both opacity and visibility */
}

    .sound-style.hide.rel {
        display: flex;
        background: red;
    }

    .ind {
        border: 1px red;
    }

.sound-style.importantDisplay {
    display: flex !important;
    /* opacity: 1 !important; */
}

.relative {
/*    background: red;*/
    order: 2 !important;

/*    grid-row: 2;*/
}

.fadeOut {
/*   animation: fadeOut 0.8s ease-out !important;*/
   animation: fadeOut 0.8s ease-out 0ms !important;
}

    
.fadeOut2 {
/*   animation: fadeOut 0.8s ease-out !important;*/
   animation: fadeOut 0.8s ease-out 500ms !important;
}
    @keyframes fadeOut {
      0% {
        opacity: 1;
/*        transform: translateY(0px);*/
      }
      100% {
        opacity: 0;
/*        transform: translateY(-5px);*/
      }
    }
    
.fadeIn {
/*   animation: fadeIn 0.5s ease-in !important;*/
   animation: fadeIn 0.5s ease-in 0ms !important;
}

.fadeIn2 {
/*   animation: fadeIn 0.5s ease-in !important;*/
   animation: fadeIn 0.5s ease-in 0ms !important;
}

    @keyframes fadeIn {
      0% {
        opacity: 0;
/*        transform: translateY(-6px);*/
      }
      100% {
        opacity: 1;
/*        transform: translateY(0px);*/
      }
    }


.sound-style.dispnone {
    display: none !important;
    transition: all 100ms ease-in-out;
    opacity: 0;
}

.sound-style.dispnone.disp {
    order: 1;
    grid-column: span 6;
    display: block !important;
    background: transparent;
    box-shadow: none;
    width: auto;
    height: 22px;
    cursor: auto;
    pointer-events: none;
    justify-self: start;
    left: 71px;
    position: relative;
    background: rgb(217 217 217 / 31%);
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

    .sound-style.dispnone.disp > span {
        font-size: 16px !important;
        color: #ffffff69;
        opacity: 1;
        z-index: 1000;
    }

    .sound-style.dispnone.disp.hiderel {
        display: none !important;
    }

.sound-style.enlarged {
/*    position: fixed;*/
    /*top: 50%;
    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
   /* transform: translateX(calc(-50vw + 28px)) translateY(calc(-50vh + 28px)) !important;
    z-index: 999;*/
/*    width: 500px;*/
/*    height: 500px;*/
    transform: scale(1) !important;
    z-index: 20;
}

.closeDescription {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  background: rgb(86 35 121 / 11%);
  border-radius: 50%;
  color: #4d205f6e;
  cursor: pointer;
  transition: all 200ms ease-in-out;

}

    .closeDescription:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

.titleContent {
    padding: 20px 0 20px 0;
    font-size: 20px;
    font-weight: 600;
}

.descriptionContent {
    padding: 10px 0 10px 0;
}

.relatedTitleContent {
    padding: 20px 0 20px 0;
    font-size: 16px;
    font-weight: 600;
}

.activateContent {
    position: absolute;
    bottom: 20px;
    right: 20px;
    border: none;
    border-radius: 3px;
    color: #4d205f6e;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    padding: 9px;
    cursor: pointer;
/*    border: 1px solid rgb(236 231 240);*/
/*background: rgb(86 35 121 / 11%);*/
}

    .activateContent:hover {
        cursor: pointer !important;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    
    }

    .content {
    }

/*.expandedSound {
    background: white;
    padding: 1rem 1rem;
    z-index: 1000;
    position: relative;
    font-size: initial;
    width: fit-content;
}

.expand:hover {
    transform: scale(1.0) !important;
    background: white !important;
}

    .expand p {
        padding-top: 1rem;
        line-height: 150%;
    }

    .expand {
        width: 500px !important;
        height: fit-content !important;
        z-index: 10000;
        background: white;
        padding: 20px;
        position: fixed;
          top: 25%;
          transform: translate(-50%, -50%);
          transition: all 200ms ease-in-out !important;
    }*/