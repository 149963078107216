input:focus-visible { outline: none; }

.default {
	background: rgba(217, 217, 217, 0.5);
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	margin-top: 0.5px;
	height: 33px;
	border: 1px solid #cacaca00;
	width: 197px;
	padding-left: 15px;
	margin-left: 45px;
	transition: all ease-in-out 100ms;
	padding-top: 2px;
}

	.default.scrolled {
		background: rgba(217, 217, 217, 0.75);
	}

.default:focus-visible {
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3) !important;
  height: 36px !important;
  width: 208px !important;
  background: rgba(255,255,255,1);
	border: 1px solid #cacaca00;
}

.input-container {
	position: relative;
	display: inline-block;
	z-index: 2000;
}

.clear-icon {
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	color: #aaa;
	font-size: 14px;
}

.clear-icon:hover {
 	color: #666;
}

input:not(:focus-visible):not(:placeholder-shown) {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3) !important;
    height: 36px !important;
    width: 208px !important;
    background: rgba(255,255,255,1);
	border: 1px solid #cacaca00;
}



