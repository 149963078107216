.App {
    user-select: none;
    height: 100vh;
}

.player-controls {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    min-width: 350px;
}

    .button {
        cursor: pointer;
        transition: all ease-in-out 200ms;
    }

    .button:hover {
        color: var(--hover-color);
    }

   /* .modal-open { 
        position: fixed;
    }*/