.filter {
    position: sticky;
    top: 81.5px;
    /* border-bottom: 2px dashed var(--main-color); */
    /* background: #ffffff7d; */
    height: 42px;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    /* padding-top: 5px; */
}

.filter-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;


    width: 1080px;
    height: 100%;
    background: rgba(217, 217, 217, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 15px;
    transition: all 200ms ease-in-out;
}

    @media (min-width: 576px) {
        .filter-container {
            width: 100%;
        }
    }

    @media (min-width: 768px) {
        .filter-container {
            width: 100%;
        }
    }

    @media (min-width: 992px) {
        .filter-container {
            width: 100%;
        }
    }

    @media (min-width: 1050px) {
        .filter-container {
            width: 1080px;
        }
    }

    .filter-container.scrolled {
        background: rgba(217, 217, 217, 0.7) !important;
    }

/*To be deleted*/
    #root > div > div.mainview > div > div.filter > div > div:nth-child(11) > div > span > input {
        background: rgba(217, 217, 217, 0.5);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        margin-top: -1px;
        height: 32px;
        border: 1px solid #cacaca00;
        width: 212px;
        margin-left: 30px;
        transition: all ease-in-out 100ms;
    }

    #root > div > div.mainview > div > div.filter > div > div:nth-child(11) > div > span > input:focus {
        /* border: 2px solid #ab9bb3 !important; */
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3) !important;
        height: 36px !important;
        width: 208px !important;
        background: rgba(255,255,255,1);
    }

    #root > div > div.mainview > div > div.filter > div > div:nth-child(11) > div > div {
        display: none;
    }

    #root > div > div.mainview > div > div.filter > div > div:nth-child(11) > div > span > input > div:focus {
        /* margin-left: -3px; */
    }

.request {
    color: #ffffff8f;
    margin-left: 55px;
    margin-top: 3px;
    cursor: pointer;
}

.expendable-card-container {
  width: 100px;
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
    right: 25px;
    position: relative;
}