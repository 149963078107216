.volume-container {

}

.mark {
    background: #D9D9D9;
    border: 0.5px solid rgba(148, 125, 159, 0.47);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,bottom 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

    .mark:hover {
        box-shadow: 0px 0px 0px 8px rgb(3 12 21 / 16%);
        transform: translate(-50%, -50%);
        top: 50%;
    }

    .mark:active {
        box-shadow: 0px 0px 0px 6px rgb(3 12 21 / 16%);
        transform: translate(-50%, -50%);
        top: 50%;
    }