.label {
    padding: 4px;
    margin: 0 15px;
    cursor: pointer;
    transition: all ease-in-out 200ms;
    font-size: 12px;
    color: white;
}

    .label:hover {
        color: var(--hover-color);
    }

.label.active {
    /* color: #41204A; */
}

.label.active::after {
    display: block;
    content: "";
    position: relative;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 2px;
    background-color: #41204A;
    transition: all 1000ms ease;
}

